export default {
  "nav": {
    "main": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الرئيسية"])},
    "orders": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["طلباتي"])},
    "pending": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["قيد الموافقة"])},
    "wainting": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["بانتظار الدفع"])},
    "finished": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تم الانتهاء"])},
    "finance": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["المالية"])},
    "wallet": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["المحفظة والطلبات"])},
    "settle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["طلبات التسوية"])},
    "config": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الاعدادات"])},
    "reg-setting": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اعدادات التسجيل"])},
    "entity-setting": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["بيانات الكيان"])},
    "levels-setting": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["المراحل"])},
    "facs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الكليات"])},
    "departs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الأقسام"])},
    "years": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["السنين الدراسية"])},
    "prices": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الاسعار"])},
    "shape": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["نموذج تسجيل الطلاب"])},
    "rates": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["التقييمات"])},
    "directs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["المشرفين"])},
    "directs_mng": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["إدارة المشرفين"])},
    "reports": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تقارير المشرفين"])},
    "contact": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تواصل معنا"])},
    "logout": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تسجيل الخروج"])}
  },
  "home": {
    "dash": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["لوحة التحكم"])},
    "intro": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["مرحبا بك اهلا بعودتك"])},
    "income": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الطلبات الواردة"])},
    "active": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الطلبات النشطة"])}
  }
}