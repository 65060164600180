import { createRouter, createWebHistory } from "vue-router";

import authLayout from "@/views/authLayout.vue";
import dashboardLayout from "@/views/dashboardLayout.vue";
const routes = [
  {
    path: "/auth",
    component: authLayout,
    children: [
      {
        path: "/login",
        component: () => import("@/views/auth/loginView.vue"),
      },
      {
        path: "/register",
        component: () => import("@/views/auth/registerView.vue"),
      },
      {
        path: "/passwordConfirmation",
        component: () => import("@/views/auth/passwordConfirmation.vue"),
      },
      {
        path: "/contactUs",
        component: () => import("@/views/auth/contactUs.vue"),
      },
      {
        path: "/:catchAll(.*)*",
        component: () => import("@/views/auth/notFoundView.vue"),
      },
    ],
  },
  {
    path: "/dashboard",
    component: dashboardLayout,
    children: [
      // main
      {
        path: "/",
        component: () => import("@/views/dashboard/homeView.vue"),
        meta: { authIsRequired: true },
      },
      {
        path: "/contact",
        component: () => import("@/views/dashboard/contactusView.vue"),
        meta: { authIsRequired: true },
      },
      {
        path: "/rates",
        component: () => import("@/views/dashboard/ratesView.vue"),
        meta: { authIsRequired: true },
      },
      {
        path: "/notifications",
        component: () => import("@/views/dashboard/notificationsView.vue"),
        meta: { authIsRequired: true },
      },
      // orders
      {
        path: "/orders/pending",
        component: () => import("@/views/dashboard/orders/pendingOrders.vue"),
        meta: { authIsRequired: true },
      },
      {
        path: "/orders/waitingPay",
        component: () => import("@/views/dashboard/orders/waiting_pay.vue"),
        meta: { authIsRequired: true },
      },
      {
        path: "/orders/finished",
        component: () => import("@/views/dashboard/orders/finishedOrders.vue"),
        meta: { authIsRequired: true },
      },
      {
        path: "/order/details/:id",
        component: () => import("@/views/dashboard/orders/orderDetails.vue"),
        meta: { authIsRequired: true },
      },
      // financial
      {
        path: "/financial/wallet",
        component: () => import("@/views/dashboard/financial/walletOrders.vue"),
        meta: { authIsRequired: true },
      },
      {
        path: "/financial/settlement",
        component: () =>
          import("@/views/dashboard/financial/settlementView.vue"),
        meta: { authIsRequired: true },
      },
      {
        path: "/financial/details/:id",
        component: () =>
          import("@/views/dashboard/financial/settlement_details.vue"),
        meta: { authIsRequired: true },
      },
      // director
      {
        path: "/directors/management",
        component: () =>
          import("@/views/dashboard/director/manageDirector.vue"),
        meta: { authIsRequired: true },
      },
      {
        path: "/directors/reports",
        component: () =>
          import("@/views/dashboard/director/reportsDirector.vue"),
        meta: { authIsRequired: true },
      },
      {
        path: "/directors/add/:id",
        component: () => import("@/views/dashboard/director/addDirector.vue"),
        meta: { authIsRequired: true },
      },
      {
        path: "/directors/show/:id",
        component: () => import("@/views/dashboard/director/directorInfo.vue"),
        meta: { authIsRequired: true },
      },
      // config
      {
        path: "/config/department",
        component: () =>
          import("@/views/dashboard/config/departmentsSetting.vue"),
        meta: { authIsRequired: true },
      },
      {
        path: "/config/entity",
        component: () => import("@/views/dashboard/config/entitySetting.vue"),
        meta: { authIsRequired: true },
      },
      {
        path: "/config/faculty",
        component: () =>
          import("@/views/dashboard/config/faculitiesSetting.vue"),
        meta: { authIsRequired: true },
      },
      {
        path: "/config/pricesNursery",
        component: () => import("@/views/dashboard/config/pricesSchools.vue"),
        meta: { authIsRequired: true },
      },
      {
        path: "/config/pricesSchools",
        component: () => import("@/views/dashboard/config/pricesSchools.vue"),
        meta: { authIsRequired: true },
      },
      {
        path: "/config/prices",
        component: () => import("@/views/dashboard/config/pricesSettings.vue"),
        meta: { authIsRequired: true },
      },
      {
        path: "/config/register",
        component: () => import("@/views/dashboard/config/registerSetting.vue"),
        meta: { authIsRequired: true },
      },
      {
        path: "/config/shape",
        component: () =>
          import("@/views/dashboard/config/registerShapeSetting.vue"),
        meta: { authIsRequired: true },
      },
      {
        path: "/config/school",
        component: () => import("@/views/dashboard/config/schoolsSetting.vue"),
        meta: { authIsRequired: true },
      },
      {
        path: "/config/studYears",
        meta: { authIsRequired: true },
        component: () =>
          import("@/views/dashboard/config/studyYearsSetting.vue"),
      },
      {
        path: "/config/studYearsSchools",
        meta: { authIsRequired: true },
        component: () =>
          import("@/views/dashboard/config/studYearsSchools.vue"),
      },
      {
        path: "/config/studYearsNursery",
        meta: { authIsRequired: true },
        component: () =>
          import("@/views/dashboard/config/studYearsSchools.vue"),
      },
      {
        path: "/config/studYearsInst",
        meta: { authIsRequired: true },
        component: () =>
          import("@/views/dashboard/config/studYearsInst.vue"),
      },
      {
        path: "/config/pricesInst",
        meta: { authIsRequired: true },
        component: () =>
          import("@/views/dashboard/config/pricesInst.vue"),
      },

      // generals
      {
        path: "/addFaculty/:id",
        component: () => import("@/views/dashboard/general/addFaculty.vue"),
        meta: { authIsRequired: true },
      },
      {
        path: "/addDepartment/:id",
        component: () => import("@/views/dashboard/general/addDepartment.vue"),
        meta: { authIsRequired: true },
      },
    ],
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior() {
    window.scrollTo(0, 0);
  },
});

router.beforeEach((to, from, next) => {
  if (to.matched.some((auth) => auth.meta.authIsRequired)) {
    if (!localStorage.getItem("token")) {
      router.push("/login");
    } else {
      next();
    }
  } else {
    next();
  }
});

export default router;
