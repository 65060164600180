<template>
  <header id="header" class="pt-4 pb-4 px-4">
    <section class="header_content flex_end">
      <!-- user interaction  -->
      <div class="d-flex align-items-baseline">
        <div class="mx-3">
          <button class="btn main_btn pt-2 pb-2 px-5" @click="send_nots = true">
            ارسال اشعار للمستخدمين
          </button>
        </div>

        <!-- notfications  -->
        <router-link
          to="/notifications"
          class="position-relative btn nots mx-3"
        >
          <i class="fa-regular fa-bell"></i>
          <span class="not_count" v-if="notificationCount != 0">
            {{ notificationCount }}
          </span>
        </router-link>

        <!-- admin  -->

        <div class="admin dropdown">
          <button
            class="btn d-flex dropdown-toggle pointer-none"
            type="button"
            id="dropdownMenuButton1"
            data-bs-toggle="dropdown"
            aria-expanded="false"
          >
            <div class="admin_image">
              <img :src="imageAdmin" alt="admin image" class="admin-img" />
            </div>
            <div class="admin_info mx-2">
              <h6 class="name fw-bold">{{ nameAdmin }}</h6>
              <span class="title fw-6 gray_color">مدير النظام </span>
            </div>
          </button>
          <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton1">
            <li class="single">
              <router-link to="/center/about"> من نحن</router-link>
            </li>
            <li class="single">
              <router-link to="/center/faqs">الاسئلة الشائعة </router-link>
            </li>
            <li class="single">
              <router-link to="/center/terms"> الشروط والأحكام </router-link>
            </li>
          </ul>
        </div>
      </div>
    </section>
  </header>

  <!-- send notification to users  -->
  <!-- otp  -->
  <Dialog
    v-model:visible="send_nots"
    modal
    :style="{ width: '34rem' }"
    :breakpoints="{ '1199px': '75vw', '575px': '90vw' }"
  >
    <form @submit.prevent="sendNotification" ref="form" class="send_not_form">
      <div class="form-group">
        <label for=""> نص الإشعار </label>
        <textarea
          cols="30"
          name="message"
          rows="10"
          class="form-control"
          placeholder="يرجى ادخال نص الإشعار هنا"
        ></textarea>
      </div>
      <div class="mt-3">
        <button
          type="submit"
          :disabled="disabled"
          class="btn main_btn pt-2 pb-2 w-100 fs-19 fw-6"
        >
          <div v-if="!disabled">إرسال إشعار</div>
          <div class="loading" role="status" v-if="disabled">
            جاري الإرسال
            <div class="spinner-small-white" v-if="disabled"></div>
          </div>
        </button>
      </div>
    </form>
  </Dialog>

  <!-- success  -->
  <Dialog
    v-model:visible="successModal"
    modal
    :style="{ width: '30rem' }"
    :breakpoints="{ '1199px': '75vw', '575px': '90vw' }"
  >
    <section id="otp" class="px-5">
      <div class="otp_gif mx-auto d-flex mt-1">
        <img
          :src="require('@/assets/imgs/cqFXlv9eyA.gif')"
          class="image"
          alt=""
        />
      </div>
      <h5 class="fw-bold main_color text-center mb-2">
        تم ارسال الاشعار بنجاح
      </h5>

      <!-- gif  -->

      <div class="flex_center mt-4">
        <router-link
          class="main_btn btn pt-3 pb-3 w-100"
          to="/"
          @click="successModal = false"
        >
          المتابعة
        </router-link>
      </div>
    </section>
  </Dialog>
</template>

<script>
import axios from "axios";
import Dialog from "primevue/dialog";

export default {
  data() {
    return {
      notificationCount: "",
      name: "",
      nameAdmin: "",
      title: "",
      image: "",
      imageAdmin: "",
      notifyCount: "",
      send_nots: false,
      disabled: false,
      successModal: false,
    };
  },
  components: {
    Dialog,
  },
  methods: {
    async sendNotification() {
      const fd = new FormData(this.$refs.form);
      this.disabled = true;
      const token = localStorage.getItem("token");
      const headers = {
        Authorization: `Bearer ${token}`,
      };

      await axios
        .post("/entity/notifications/send-notification", fd, { headers })
        .then((res) => {
          if (res.data.key === "success") {
            this.send_nots = false;
            this.successModal = true;
          } else {
            this.$toast.add({
              severity: "error",
              summary: res.data.msg,
              life: 3000,
            });
          }
          this.disabled = false;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    switchLanguage(locale) {
      let lang = locale;
      if (this.$i18n.locale == "ar") {
        lang = "en";
      }

      if (localStorage.getItem("locale")) {
        localStorage.removeItem("locale");
      }
      localStorage.setItem("locale", lang);

      location.reload();
    },
    switchDrop() {
      this.$refs.profile.classList.toggle("active");
    },

    // get home
    async getNotiCount() {
      await axios
        .get("/entity/notification-unread-count", {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        })
        .then((response) => {
          if (response.data.key != "unauthenticated") {
            this.notificationCount = response.data.data.count;
          } else {
            this.$router.push("/login");
            localStorage.removeItem("token");
          }
        });
    },
    async getMainSettings() {
      await axios
        .get("/entity/setting/responsible-data", {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        })
        .then((response) => {
          this.imageAdmin = response.data.data.image;
          this.nameAdmin = response.data.data.name;
        });
    },

    switchSide() {
      document.querySelector("#sidebar").classList.toggle("active");
      document.querySelector("#header").classList.toggle("active");
      document.querySelector("#content").classList.toggle("active");
    },
    opeSide() {
      document.querySelector("#sidebar").classList.add("active");
    },
  },
  mounted() {
    this.getNotiCount();

    this.getMainSettings();
  },
  watch: {
    $route() {
      this.getNotiCount();
    },
  },
};
</script>

<style lang="scss">
.send_not_form {
  textarea {
    height: 100px;
    background: whitesmoke;
    border: none;
  }
}
.admin {
  margin-left: 100px;
}
.openSide {
  display: none !important;
}
.not_count {
  position: absolute;
  background: #2858f6;
  color: #fff;
  width: 20px;
  height: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  top: -10px;
  right: -8px;
  font-size: 12px;
}
.dropdown-menu {
  border-radius: 6px !important;
  background-color: #fff !important;
  box-shadow: 0px 0px 10px rgba(51, 51, 51, 0.262745098) !important;
  width: 170px;
  transition: 0.6s all;
  border: none !important;
  .single {
    color: #333;
    text-align: center;
    padding-bottom: 8px;
    &:not(:last-of-type) {
      border-bottom: 1px solid #cccccc4d;
    }
  }
  &.active {
    opacity: 1;
  }
}
.profile_icon {
  position: absolute;
  left: 3px;
  font-size: 13px;
  top: 27%;
}
.flag_img {
  width: 25px;
}
.dropdown-toggle::after {
  display: none !important;
}
.admin {
  cursor: pointer;
}
.btn.show {
  border: none !important;
}
</style>
